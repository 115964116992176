// Tabs
//


.majestic-tabs {
  border-bottom:0;

  .nav-link {

    border: 2px solid transparent;
    color:$gray-800;
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-color: $nav-tabs-link-active-color;
  }
}
.nav-link{
  white-space: nowrap;
}

.back-to-top{
   position: fixed;
    bottom: toRem(20);
    right: toRem(20);
    height:toRem(40);
    width:toRem(40);
    text-align: center;
    line-height: toRem(40);
    text-align: center;
    opacity: 0;
    border-radius: 50%;
    color: #fff;
    transition: all 0.4s ease-in-out;
    z-index: 999;


}

.sticky-top{
  top:toRem(30);
}

// .clearfix::after {
//   display: block;
//   clear: both;
//   content: "";
// }
